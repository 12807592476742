require('js-cookie');

const form    = document.getElementById('jobForm');
form.onsubmit = function (e) {
  e.preventDefault();

  const formName  = document.getElementById('name').value;
  const formMail  = document.getElementById('email').value;
  const formPhone = document.getElementById('phone_number').value;
  const formCity  = document.getElementById('city').value;
  const formCar  = document.getElementById('car').value;
  const formLinguistic = document.getElementById('linguistic_proficiency').value;
  const workingTime = document.getElementById('working_time').value;

  if(formLinguistic === 'Grundwortschatz'  || workingTime === 'Nein' ){
    dataLayer.push({
      event: "trackEvent",
      eventCategory: "Fountain",
      eventAction: "Submit"
    })

    setTimeout(function() {
      window.location.replace('https://' + window.location.hostname + '/fountain-danke/');
    }, 2000);

  }else{
    const data = {
      'name': formName,
      'email': formMail,
      'phone_number': formPhone,
      'data': {
        'city': formCity,
        'linguistic_proficiency': formLinguistic,
        'car': formCar,
        'utm_source': (Cookies.get('utm_source')),
        'utm_medium': (Cookies.get('utm_medium')),
        'utm_campaign': (Cookies.get('utm_campaign')),
        'utm_content': (Cookies.get('utm_content')),
        'utm_term': (Cookies.get('utm_term')),
        'gclid': (Cookies.get('gclid')),
        'fbclid': (Cookies.get('fbclid'))
      },
      'funnel_id': "458fa92e-628b-4b93-9f7a-d10732f827e6"
    };

    const url = 'https://nhn2q8i44i.execute-api.eu-central-1.amazonaws.com/prod/apply'
    const requestOptions = {
      url: url,
      method: 'POST',
      data: data,
    }
    axios(requestOptions)
      .then(function (response) {
        dataLayer.push({
          event: "trackEvent",
          eventCategory: "Fountain",
          eventAction: "Portal"
        })

        setTimeout(function() {
          window.location.replace(response.data.portal_url)
        }, 2000);

      })
      .catch(function (error) {
        console.log(error);
        window.location.replace('https://' + window.location.hostname + '/fountain-error/');
      })
  }
};
